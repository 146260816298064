<template>
  <div>
    <Toasts ref="Toasts"></Toasts>
    <PlgUsersApi ref="PlgUsersApi"></PlgUsersApi>
    <section v-if="user" class="profile">
      <div class="row">
        <div class="col-12">
          <div class="card border rounded">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4">
                  <div class="border-bottom text-center pb-4">
                    <img
                      src="../../assets/images/faces/user-placeholder.png"
                      alt="profile"
                      class="img-lg rounded-circle mb-3"
                    />
                    <b-row align-h="center" class="my-2">
                      {{ user.name }}
                    </b-row>
                    <div>
                      <b-button-group vertical>
                        <b-button
                          variant="primary"
                          @click="handleFormSwitch('#user-data')"
                          >Informações Pessoais</b-button
                        >
                        <b-button
                          variant="primary"
                          @click="handleFormSwitch('#address-data')"
                          class="my-2"
                          >Endereço</b-button
                        >
                        <b-button
                          variant="primary"
                          @click="handleFormSwitch('#bank-data')"
                          >Dados Bancários</b-button
                        >
                        <!-- <b-button v-show="!isLoadingResetPass" variant="primary" @click="sendResetEmail" class="mt-2">Alterar Senha</b-button> -->
                        <!-- <b-button v-show="isLoadingResetPass" variant="primary" disabled class="mt-2"><b-spinner small></b-spinner></b-button> -->
                      </b-button-group>
                    </div>
                  </div>
                </div>
                <div class="col-lg-8">
                  <form
                    v-show="userInfoForm && !(addressForm && bankForm)"
                    @submit.prevent=""
                    @reset.prevent=""
                    class="forms-sample mt-3"
                  >
                    <div class="col-xl-12">
                      <div class="row">
                        <!--DETALHES-->
                        <div class="col-xl-12">
                          <div class="">
                            <div class="">
                              <h4 class="card-title">Detalhes</h4>
                              <div class="text-right">
                                <b-btn
                                  v-show="!isLoading"
                                  class="btn btn-success btn-sm text-white mr-2"
                                  @click="sendUserDataApi()"
                                  v-b-tooltip.html.bottom
                                  title="Confirmar"
                                  ><i class="mdi mdi-check" />
                                </b-btn>
                                <b-btn
                                  v-show="isLoading"
                                  class="btn btn-success btn-sm text-white mr-2"
                                  @click="sendUserDataApi()"
                                  v-b-tooltip.html.bottom
                                  disabled
                                >
                                  <div class="text-center align-items-center">
                                    <b-spinner small></b-spinner>
                                  </div>
                                </b-btn>
                              </div>
                              <div class="container-fluid">
                                <b-form-group
                                  label="Nome"
                                  label-for="inputName"
                                >
                                  <b-form-input
                                    v-model="user.name"
                                    type="text"
                                    id="inputName"
                                    size="sm"
                                    :class="{
                                      'is-invalid':
                                        submitted && $v.user.name.$error,
                                    }"
                                  ></b-form-input>
                                  <div
                                    v-if="submitted && $v.user.name.$error"
                                    class="invalid-feedback"
                                  >
                                    <span v-if="!$v.user.name.required"
                                      >Nome é obrigatório.</span
                                    >
                                  </div>
                                </b-form-group>
                                <b-form-group
                                  label="Celular"
                                  label-for="inputCellphone"
                                >
                                  <b-form-input
                                    v-model="user.cellphone"
                                    v-mask="[
                                      '(##) ####-####',
                                      '(##) #####-####',
                                    ]"
                                    type="text"
                                    id="inputCellphone"
                                    size="sm"
                                    :class="{
                                      'is-invalid':
                                        submitted && $v.user.cellphone.$error,
                                    }"
                                  ></b-form-input>
                                  <div
                                    v-if="submitted && $v.user.cellphone.$error"
                                    class="invalid-feedback"
                                  >
                                    <span v-if="!$v.user.cellphone.required"
                                      >Celular é obrigatório.</span
                                    >
                                  </div>
                                </b-form-group>
                                <b-form-group
                                  label="Data de Nascimento"
                                  label-for="inputBirthday"
                                >
                                  <b-input-group>
                                    <b-form-input
                                      v-model="user.birthday"
                                      v-mask="['##/##/####']"
                                      id="inputBirthday"
                                      placeholder="Data de Nascimento"
                                    >
                                    </b-form-input>
                                    <b-form-datepicker
                                      v-model="birthdayFromDatePicker"
                                      button-only
                                      button-variant="primary"
                                      placeholder="Escolha uma data"
                                      locale="pt-br"
                                      :date-format-options="{
                                        year: 'numeric',
                                        month: 'long',
                                        day: '2-digit',
                                      }"
                                      :max="maxDate"
                                      hide-header
                                      size="sm"
                                      selected-variant="primary"
                                      :initial-date="initialDate"
                                      label-no-date-selected
                                    ></b-form-datepicker>
                                  </b-input-group>
                                </b-form-group>
                                <b-form-group
                                  label="Email"
                                  label-for="inputEmail"
                                >
                                  <b-form-input
                                    v-model="user.email"
                                    type="text"
                                    id="inputEmail"
                                    size="sm"
                                    :class="{
                                      'is-invalid':
                                        submitted && $v.user.email.$error,
                                    }"
                                  ></b-form-input>
                                  <div
                                    v-if="submitted && $v.user.email.$error"
                                    class="invalid-feedback"
                                  >
                                    <span v-if="!$v.user.email.required"
                                      >Email é obrigatório.</span
                                    >
                                  </div>
                                  <div
                                    v-if="submitted && $v.user.email.$error"
                                    class="invalid-feedback"
                                  >
                                    <span v-if="!$v.user.email.email"
                                      >Email deve ser válido.</span
                                    >
                                  </div>
                                </b-form-group>
                                <b-form-group
                                  label="Documento (CPF/CNPJ)"
                                  label-for="inputFederalId"
                                >
                                  <b-input-group>
                                    <b-form-input
                                      id="inputFederalId"
                                      v-model="user.federal_id"
                                      placeholder="Documento"
                                      v-mask="
                                        user.is_company
                                          ? '##.###.###/####-##'
                                          : '###.###.###-##'
                                      "
                                      type="text"
                                      :class="{
                                        'is-invalid':
                                          submitted &&
                                          $v.user.federal_id.$error,
                                      }"
                                    ></b-form-input>
                                    <b-input-group-append>
                                      <b-form-radio-group
                                        v-model="user.is_company"
                                        button-variant="outline-primary"
                                        :options="documents"
                                        name="radios-btn-default"
                                        size="sm"
                                        buttons
                                      ></b-form-radio-group>
                                    </b-input-group-append>
                                    <div
                                      v-if="
                                        submitted && $v.user.federal_id.$error
                                      "
                                      class="invalid-feedback"
                                    >
                                      <span
                                        >{{
                                          user.is_company ? "CNPJ" : "CPF"
                                        }}
                                        inválido!</span
                                      >
                                    </div>
                                  </b-input-group>
                                </b-form-group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <form
                    v-show="addressForm && !(userInfoForm && bankForm)"
                    class="forms-sample mt-3"
                  >
                    <div class="col-xl-12">
                      <div class="row">
                        <!--ENDEREÇO DO USUÁRIO-->
                        <div class="col-xl-12">
                          <div class="">
                            <div class="">
                              <h4 class="card-title">Endereço</h4>
                              <div class="text-right">
                                <b-btn
                                  v-show="!isLoading"
                                  class="btn btn-success btn-sm text-white mr-2"
                                  @click="sendUserDataApi()"
                                  v-b-tooltip.html.bottom
                                  title="Confirmar"
                                  ><i class="mdi mdi-check" />
                                </b-btn>
                                <b-btn
                                  v-show="isLoading"
                                  class="btn btn-success btn-sm text-white mr-2"
                                  @click="sendUserDataApi()"
                                  v-b-tooltip.html.bottom
                                  disabled
                                >
                                  <div class="text-center align-items-center">
                                    <b-spinner small></b-spinner>
                                  </div>
                                </b-btn>
                              </div>
                              <div class="container-fluid">
                                <b-form-group
                                  label="CEP"
                                  label-for="inputZipCode"
                                >
                                  <b-form-input
                                    v-model="user.zip_code"
                                    type="text"
                                    id="inputZipCode"
                                    v-mask="['#####-###']"
                                    @change="getZipCodeData"
                                    placeholder="CEP"
                                    size="sm"
                                    :class="{
                                      'is-invalid':
                                        submitted && $v.user.zip_code.$error,
                                    }"
                                  ></b-form-input>
                                  <div
                                    v-if="submitted && $v.user.zip_code.$error"
                                    class="invalid-feedback"
                                  >
                                    <span v-if="!$v.user.zip_code.required"
                                      >CEP é obrigatório.</span
                                    >
                                  </div>
                                </b-form-group>
                                <b-form-group
                                  label="Logradouro"
                                  label-for="inputAddress"
                                >
                                  <b-form-input
                                    v-model="user.address"
                                    type="text"
                                    id="inputAddress"
                                    placeholder="Logradouro"
                                    size="sm"
                                    :class="{
                                      'is-invalid':
                                        submitted && $v.user.address.$error,
                                    }"
                                  ></b-form-input>
                                  <div
                                    v-if="submitted && $v.user.address.$error"
                                    class="invalid-feedback"
                                  >
                                    <span v-if="!$v.user.address.required"
                                      >Logradouro é obrigatório.</span
                                    >
                                  </div>
                                </b-form-group>
                                <b-form-group
                                  label="Número"
                                  label-for="inputHouseNumber"
                                >
                                  <b-form-input
                                    v-model="user.house_number"
                                    type="text"
                                    id="inputHouseNumber"
                                    placeholder="Número"
                                    size="sm"
                                    :class="{
                                      'is-invalid':
                                        submitted &&
                                        $v.user.house_number.$error,
                                    }"
                                  ></b-form-input>
                                  <div
                                    v-if="
                                      submitted && $v.user.house_number.$error
                                    "
                                    class="invalid-feedback"
                                  >
                                    <span v-if="!$v.user.house_number.required"
                                      >Número é obrigatório.</span
                                    >
                                  </div>
                                </b-form-group>
                                <b-form-group
                                  label="Complemento"
                                  label-for="inputAddressComplement"
                                >
                                  <b-form-input
                                    v-model="user.address_complement"
                                    type="text"
                                    id="inputAddressComplement"
                                    placeholder="Complemento"
                                    size="sm"
                                  ></b-form-input>
                                </b-form-group>
                                <b-form-group
                                  label="Bairro"
                                  label-for="inputQuarter"
                                >
                                  <b-form-input
                                    v-model="user.quarter"
                                    type="text"
                                    id="inputQuarter"
                                    placeholder="Bairro"
                                    size="sm"
                                    :class="{
                                      'is-invalid':
                                        submitted && $v.user.quarter.$error,
                                    }"
                                  ></b-form-input>
                                  <div
                                    v-if="submitted && $v.user.quarter.$error"
                                    class="invalid-feedback"
                                  >
                                    <span v-if="!$v.user.quarter.required"
                                      >Bairro é obrigatório.</span
                                    >
                                  </div>
                                </b-form-group>
                                <b-form-group
                                  label="Cidade"
                                  label-for="inputCity"
                                >
                                  <b-form-select
                                    v-model="user.city"
                                    :options="citiesOptions"
                                    id="inputCity"
                                    placeholder="Cidade"
                                    size="md"
                                    :class="{
                                      'is-invalid':
                                        submitted && $v.user.city.$error,
                                    }"
                                  ></b-form-select>
                                  <div
                                    v-if="submitted && $v.user.city.$error"
                                    class="invalid-feedback"
                                  >
                                    <span v-if="!$v.user.city.required"
                                      >Cidade é obrigatória.</span
                                    >
                                  </div>
                                </b-form-group>
                                <b-form-group
                                  label="Estado"
                                  label-for="inputState"
                                >
                                  <b-form-select
                                    v-model="user.state"
                                    :options="userStatesOpts"
                                    id="inputState"
                                    @change="getCitiesOptions"
                                    placeholder="Estado"
                                    size="md"
                                    :class="{
                                      'is-invalid':
                                        submitted && $v.user.state.$error,
                                    }"
                                  ></b-form-select>
                                  <div
                                    v-if="submitted && $v.user.state.$error"
                                    class="invalid-feedback"
                                  >
                                    <span v-if="!$v.user.state.required"
                                      >Estado é obrigatório.</span
                                    >
                                  </div>
                                </b-form-group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <form
                    v-show="bankForm && !(userInfoForm && addressForm)"
                    class="forms-sample mt-3"
                  >
                    <div class="col-xl-12">
                      <div class="row">
                        <!--DADOS BANCÁRIOS DO USUÁRIO-->
                        <div class="col-xl-12">
                          <div class="">
                            <div class="">
                              <b-row>
                                <b-col>
                                  <h4 class="card-title">Dados Bancários</h4>
                                </b-col>
                                <b-col
                                  class="d-flex align-items-center justify-content-end"
                                >
                                  <b-badge
                                    v-if="user.id"
                                    pill
                                    class="mx-2"
                                    :variant="bankDataBadgeVariant"
                                  >
                                    <span class="mt-2">
                                      {{
                                        bankDataConfirmationStatus.toUpperCase()
                                      }}
                                    </span>
                                  </b-badge>
                                  <div class="mx-2">
                                    <b-btn
                                      v-show="!isLoading"
                                      class="btn btn-success btn-sm text-white mr-2"
                                      @click="sendUserDataApi()"
                                      v-b-tooltip.html.bottom
                                      title="Confirmar"
                                      ><i class="mdi mdi-check" />
                                    </b-btn>
                                    <b-btn
                                      v-show="isLoading"
                                      class="btn btn-success btn-sm text-white mr-2"
                                      @click="sendUserDataApi()"
                                      v-b-tooltip.html.bottom
                                      disabled
                                    >
                                      <div
                                        class="text-center align-items-center"
                                      >
                                        <b-spinner small></b-spinner>
                                      </div>
                                    </b-btn>
                                  </div>
                                </b-col>
                              </b-row>
                              <div class="container-fluid">
                                <b-form-group
                                  label="Banco/Instituição Financeira"
                                  label-for="bankCode"
                                >
                                  <multiselect
                                    v-model="user.bank_code"
                                    :options="ispbOptions"
                                    tag-placeholder="Selecione"
                                    placeholder="Selecione"
                                    selectLabel=""
                                    deselectLabel=""
                                    selectedLabel="X"
                                    label="fullName"
                                    track-by="ispb"
                                  >
                                    <template #noOptions>
                                      Faça uma busca...
                                    </template>
                                    <template #noResult>
                                      Sem resultados! Por favor, refaça sua
                                      busca.
                                    </template>
                                  </multiselect>
                                </b-form-group>
                                <b-form-group
                                  label="Agência"
                                  label-for="branchCode"
                                >
                                  <b-form-input
                                    v-model="user.branch_code"
                                    type="text"
                                    id="branchCode"
                                    v-mask="'####'"
                                    placeholder="Agência"
                                    size="sm"
                                  ></b-form-input>
                                </b-form-group>
                                <b-form-group
                                  label="Dígito da Agência"
                                  label-for="branchCodeDigit"
                                >
                                  <b-form-input
                                    v-model="user.branch_code_digit"
                                    type="text"
                                    id="branchCodeDigit"
                                    v-mask="'#'"
                                    placeholder="Dígito da Agência"
                                    size="sm"
                                  ></b-form-input>
                                </b-form-group>
                                <b-form-group
                                  label="Conta"
                                  label-for="accountNumber"
                                >
                                  <b-form-input
                                    v-model="user.account_number"
                                    type="text"
                                    id="accountNumber"
                                    placeholder="Conta"
                                    size="sm"
                                  ></b-form-input>
                                </b-form-group>
                                <b-form-group
                                  label="Dígito da Conta"
                                  label-for="accountNumberDigit"
                                >
                                  <b-form-input
                                    v-model="user.account_number_digit"
                                    type="text"
                                    id="accountNumberDigit"
                                    v-mask="'#'"
                                    placeholder="Dígito da Conta"
                                    size="sm"
                                  ></b-form-input>
                                </b-form-group>
                                <b-form-group
                                  label="Tipo de Conta"
                                  label-for="accountTypeInput"
                                >
                                  <b-form-select
                                    id="accountTypeInput"
                                    :options="accountTypeOptions"
                                    v-model="user.account_type"
                                  />
                                </b-form-group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PlgUsersApi from "../../apis/PlgUsersApi.vue";
import moment from "moment";
import { required, email, minLength } from "vuelidate/lib/validators";
import customValidators from "../../plugins/vuelidateValidators";
import states from "../../helpers/users-opts/statesOptions";
import ispbOptions from "../../helpers/users-opts/ispbOptions";
import roles from "../../helpers/users-opts/usersRolesOpts";
import Toasts from "../../components/Toasts.vue";
import { cities } from "brazil-geodata";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Multiselect from "vue-multiselect";

export default {
  name: "profile",
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const initialDate = new Date(today);
    initialDate.setFullYear(initialDate.getFullYear() - 18);
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth());
    maxDate.setDate(now.getDate());
    return {
      isLoading: false,
      userStatesOpts: states.statesOptions,
      userRolesOpts: roles.userRolesOpts,
      birthdayFromDatePicker: null,
      citiesOptions: [{ text: "Cidade", value: null }],
      bankDataFromApi: false,
      initialDate: initialDate,
      ispbOptions: ispbOptions,
      maxDate: maxDate,
      accountTypeOptions: [
        { text: "Corrente", value: "checking" },
        { text: "Poupança", value: "savings" },
        { text: "Salário", value: "salary" },
      ],
      userId: null,
      documents: [
        { text: "CPF", value: false },
        { text: "CNPJ", value: true },
      ],
      isLoadingResetPass: false,
      user: null,
      addressForm: false,
      bankForm: false,
      userInfoForm: true,
      submitted: false,
    };
  },
  components: {
    Toasts,
    PlgUsersApi,
    Multiselect,
  },
  validations: {
    user: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      cellphone: {
        required,
        minLength: minLength(11),
      },
      federal_id: {
        required,
        federalIdValidCheck: customValidators.federalIdValidCheck(),
      },
      address: {
        required,
      },
      house_number: {
        required,
      },
      quarter: {
        required,
      },
      city: {
        required,
      },
      zip_code: {
        required,
      },
      state: {
        required,
      },
    },
  },
  methods: {
    handleFormSwitch(form) {
      switch (form) {
        case "#user-data":
          this.userInfoForm = true;
          this.addressForm = false;
          this.bankForm = false;
          break;
        case "#address-data":
          this.userInfoForm = false;
          this.addressForm = true;
          this.bankForm = false;
          break;
        case "#bank-data":
          this.userInfoForm = false;
          this.addressForm = false;
          this.bankForm = true;
          break;
      }
    },
    formatDateFromDatePicker() {
      this.user.birthday = moment(this.birthdayFromDatePicker).format(
        "DD/MM/YYYY"
      );
    },
    async sendResetEmail() {
      const UserTokenPayload = {
        email: this.user.email,
      };
      this.isLoadingResetPass = true;
      let response = await this.$refs.PlgUsersApi.getTokenApi(UserTokenPayload);
      this.isLoadingResetPass = false;
      this.$refs.Toasts.showToast(response.data.status, response.data.message);
    },
    getUserBank() {
      this.user.bank_code = this.ispbOptions.filter((element) => {
        return element.ispb == this.user.bank_code;
      })[0];
    },
    clearUserAddress() {
      const userAddressFields = [
        "address",
        "house_number",
        "address_complement",
        "quarter",
        "city",
        "state",
        "zip_code",
      ];
      Object.keys(this.user).forEach((key) => {
        if (userAddressFields.includes(key)) {
          this.user[key] = null;
        }
      });
    },
    checkIfCityIsInCitiesOptions() {
      this.citiesOptions.indexOf(this.user.city) >= 0
        ? true
        : (this.user.city = null);
    },
    async getCitiesOptions() {
      this.citiesOptions = await cities.getCitiesByState(this.user.state);
      this.citiesOptions = this.citiesOptions.map((city) => {
        return city.text;
      });
      this.checkIfCityIsInCitiesOptions();
      this.citiesOptions.splice(0, 0, { text: "Cidade", value: null });
    },
    async getZipCodeData() {
      if (this.user.zip_code) {
        let response = await this.$viaCep.buscarCep(this.user.zip_code);
        this.clearUserAddress();
        this.user.address = response.logradouro;
        this.user.quarter = response.bairro;
        this.user.state = response.uf;
        this.getCitiesOptions();
        this.user.city = response.localidade;
        this.user.zip_code = response.cep;
        document.getElementById("inputHouseNumber").focus();
      }
    },
    async sendUserDataApi() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.userId = this.user.id;
        const payload = {
          user: {
            name: this.user.name,
            cellphone: this.user.cellphone,
            email: this.user.email,
            birthday: moment(this.user.birthday, "DD-MM-YYYY").toISOString(),
            role: this.user.role,
            password: this.user.password,
            federal_id: this.user.federal_id,
            address: this.user.address,
            is_company: this.user.is_company,
            house_number: this.user.house_number,
            quarter: this.user.quarter,
            zip_code: this.user.zip_code,
            bank_code: this.user.bank_code ? this.user.bank_code.ispb : null,
            branch_code: this.user.branch_code,
            branch_code_digit: this.user.branch_code_digit
              ? this.user.branch_code_digit
              : null,
            account_number: this.user.account_number,
            account_number_digit: this.user.account_number_digit
              ? this.user.account_number_digit
              : null,
            account_type: this.user.account_type,
            city: this.user.city,
            state: this.user.state,
            country: "Brazil",
          },
        };
        this.isLoading = true;
        let response = await this.$refs.PlgUsersApi.updateUserApi(
          this.userId,
          payload
        );
        if (response.status === 200) {
          const userResponse = await this.$refs.PlgUsersApi.getCurrentUser();
          this.user = userResponse.data;
          if (this.hasBankData) {
            this.bankDataFromApi = true;
          }
          this.getUserBank();
        }
        this.isLoading = false;
        this.$refs.Toasts.showToast(
          response.data.status,
          response.data.message
        );
      }
    },
  },
  computed: {
    hasBankData() {
      return (
        !!this.user.branch_code &&
        !!this.user.bank_code &&
        !!this.user.account_number &&
        !!this.user.account_type
      );
    },
    bankDataBadgeVariant() {
      switch (this.user.is_bank_data_confirmed) {
        case null:
        case undefined:
          return "warning";
        case false:
          return "danger";
        case true:
          return "success";
      }
      return null;
    },
    bankDataConfirmationStatus() {
      switch (this.user.is_bank_data_confirmed) {
        case null:
        case undefined:
          return "Pendente";
        case false:
          return "Dados Inválidos";
        case true:
          return "Dados Confirmados";
      }
      return null;
    },
    formattedBirthday() {
      return moment(this.user.birthday).format("DD/MM/YYYY");
    },
  },
  watch: {
    birthdayFromDatePicker() {
      this.formatDateFromDatePicker();
    },
  },
  async mounted() {
    let response = await this.$refs.PlgUsersApi.getCurrentUser();
    this.user = response.data;
    this.user.birthday = this.formattedBirthday;
    if (this.hasBankData) {
      this.bankDataFromApi = true;
    }
    this.getCitiesOptions();
    if (this.user.bank_code) this.getUserBank();
    const routeHash = this.$route.hash;
    this.handleFormSwitch(routeHash);
  },
};
</script>
